.App {
  color: #4278b7;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.survey-form{
  margin: 3% 30%;
  border: 1px solid #6ca5e6 ;
  border-radius: 5px;
  padding: 20px;
  padding-top: 30px;
}

#favorite{
  color: #37fa1d;
  width: 2.2rem;
  height: 2.2rem;
  margin: 5px;
  cursor: pointer;
}

#unfavorite{
  color: #fad81d;
  width: 2.2rem;
  height: 2.2rem;
  margin: 5px;
  cursor: pointer;
}

.submit{
  display:flex;
  justify-content:center;
  margin: 15px;
}

.rating{
  display:flex;
  justify-content:center;
  margin: 15px;
}

.survey-form span{
  display:flex;
  justify-content:center;
  margin: 15px;
  color: #4278b7;
  font-size: 20px;
}

.survey-form p,h4,h3{
  display:flex;
  justify-content:center;
  color: #4278b7;
  text-align: center;
}

.survey-form h3{
  margin: 20px;
}

.line {
  border-top: 1px solid #4278b7;
  margin: 0 6%;
  margin-top: 20px;
}

.messageError{
  color:#f80217 !important;
  font-size:18px;
  margin: 5px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* @media only screen 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .survey-form{
      margin: 35%;
      padding: 5px;
    }
} */

@media only screen 
  and (max-device-width: 769px)  {
    .survey-form{
      margin:  3%;
      padding: 5px;
    }
  }
